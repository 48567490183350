.banners-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #f2f2f2;

    &:last-child {
        padding-bottom: 50px;
    }

    @media(max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .banner-wrapper {
        display: flex;
        color: color('white');
        text-decoration: none;
        position: relative;

        @media(max-width: 768px) {
            margin-top: 50px;
        }

        a {
            width: 100%;
            display: block;
            height: 262px;
            background-repeat: no-repeat;
            background-size: cover;

            .banner-content-wrapper {
                position: absolute;
                bottom: 0;
                padding: 40px;

                @media(max-width: 768px) {
                    padding: 30px;
                }

                h2 {
                    font-size: 28px;
                    position: relative;
                    font-weight: 600;

                    @media(max-width: 768px) {
                        font-size: 23px;
                    }
                }

                .separator {
                    height: 2px;
                    width: 100px;
                    margin: 30px 0;
                    background-color: color('secondary');
                    display: none;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    text-decoration: none;
                }
            }
        }
    }
}